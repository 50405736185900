import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Box, Text, Stack, FormControl, Button, Skeleton, VStack, IconButton } from 'native-base';
import { Select } from '../../../Libs/CustomInputs';
import Trans from '../../Components/Trans';
import Drawer from '../../../Libs/Drawer';
import DateTimePicker from '../../../Libs/DateTimePicker';
import TranslationAction from '../../../Actions/TranslationAction';
import APIAction from '../../../Actions/APIAction';
import GeneralAction from '../../../Actions/GeneralAction';
import RequestCacheAction from '../../../Actions/RequestCacheAction';
import ClientAction from '../../../Actions/ClientAction';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import { v4 as uuidv4 } from 'uuid';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import UserAction from "../../../Actions/UserAction";

const Icon = createIconSetFromFontello(lineAwesomeConfig);

const CalendarEditPane = (props) => {

    const
        firstLoad = useRef(true),
        [formData, setFormData] = useState(null),
        [translations, setTranslations] = useState({}),
        [fullDate, setFullDate] = useState(''),
        [positions, setPositions] = useState([]),
        [departments, setDepartments] = useState([]),
        [employees, setEmployees] = useState([]),
        [employeeKey, setEmployeeKey] = useState(uuidv4()),
        [loading, setLoading] = useState(false),
        [language, setLanguage] = useState(false)
        ;

    const onFirstLoad = useCallback(() => {
        const init = async () => {
            let selectedLanguage = await TranslationAction.getSelectedLanguage();
            setLanguage(selectedLanguage);

            /* TRANLATIONS */
            setTranslations(await TranslationAction.translateInLine([
                'Select',
                'Minimum',
                'length',
                'hours',
            ]));

            /* DATE */
            let formatFullDate = await GeneralAction.formatLocal(props.date, { weekday: 'long', day: 'numeric', month: 'long' });
            setFullDate(formatFullDate);


            /* PREPARE REQUESTS */
            let requestQueue = [];
            requestQueue.push(APIAction.request({ method: 'GET', url: '/api/positions', cache: false }));
            requestQueue.push(APIAction.request({ method: 'GET', url: '/api/departments', cache: false }));
            requestQueue.push(APIAction.request({
                method: 'GET',
                url: '/api/employees',
                cache: false,
                params: {
                    checkErrors: 1,
                },
            }));

            let requestQueueRes = await Promise.all(requestQueue);

            /* POSITIONS */
            let apiPositions = requestQueueRes[0]
            apiPositions = apiPositions['hydra:member'];

            let user = await UserAction.getUser();
            let clientId = GeneralAction.iriToId(user.client);

            let positionItems = [];
            for (let position of apiPositions) {
                let alias = await GeneralAction.getAlias(position, clientId);
                positionItems.push(
                    <Select.Item label={alias} value={position['@id']} />
                );
            }
            setPositions(positionItems);

            /* DEPARTMENTS */
            let apiDepartments = requestQueueRes[1];
            apiDepartments = apiDepartments['hydra:member'];

            let departmentItems = [];
            for (let department of apiDepartments) {
                departmentItems.push(
                    <Select.Item label={department.name} value={department['@id']} />
                );
            }
            setDepartments(departmentItems);

            /* EMPLOYEES */
            let apiEmployees = requestQueueRes[2];
            apiEmployees = apiEmployees['hydra:member'];

            let employeeItems = [];
            employeeItems.push(
                <Select.Item label={'No employee'} value={'noEmployee'} />
            )
            for (let employee of apiEmployees) {
                employeeItems.push(
                    <Select.Item label={`${employee.user.firstName} ${employee.user.lastName} (${employee?.contractType?.name[selectedLanguage.code]})` } value={employee['@id']} />
                );
            }
            setEmployees(employeeItems);

            /* FORM DATA */
            let newFormData = {
                startOn: new Date(props.startOn),
                endOn: new Date(props.endOn)
            };

            if (props?.employeeId) newFormData['employee'] = props.employeeId;
            if (props?.departmentId) newFormData['department'] = props.departmentId;

            if (props.shift) {
                newFormData['shiftId'] = props.shift.shift.id;
                newFormData['position'] = props.shift.shift.position['@id'];
                newFormData['department'] = props.shift.shift?.department?.['@id'];
                newFormData['employee'] = props.shift.employee ? props.shift.employee['@id'] : null;
            }

            setFormData(newFormData);
        };
        init();
    }, [props]);

    useEffect(() => {
        if (firstLoad.current && props.open) {
            firstLoad.current = false;
            onFirstLoad();
        }
    }, [onFirstLoad, props.open]);

    //submit form
    const submit = async () => {

        setLoading(true);

        let startOn = new Date(formData.startOn);
        let endOn = new Date(formData.endOn);
        let currentDate = new Date(props.date);
        let minimumDuration = 3;

        //make sure start on date is always correct
        let formatter = new Intl.DateTimeFormat('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            timeZone: 'europe/brussels'
        });
        let refDate = formatter.format(new Date(currentDate));
        let refStartOn = formatter.format(new Date(startOn));
        let refEndOn = formatter.format(new Date(endOn));

        let startOnOffset = GeneralAction.tzOffsetToTz(startOn.getTimezoneOffset());
        let endOnOffset = GeneralAction.tzOffsetToTz(endOn.getTimezoneOffset());

        if (refDate !== refStartOn || refDate !== refEndOn) {
            startOn = new Date(startOn.setDate(currentDate.getDate()));
            startOn = new Date(startOn.setMonth(currentDate.getMonth()));
            startOn = new Date(startOn.setFullYear(currentDate.getFullYear()));
            startOn = GeneralAction.changeTimezone(startOn, startOnOffset);
            endOn = new Date(endOn.setDate(currentDate.getDate()));
            endOn = new Date(endOn.setMonth(currentDate.getMonth()));
            endOn = new Date(endOn.setFullYear(currentDate.getFullYear()));
            endOn = GeneralAction.changeTimezone(endOn, endOnOffset);
        }

        //check order dates, if not right, set next date
        while (startOn > endOn) {
            endOn = new Date(endOn.getTime() + 24 * 60 * 60 * 1000);
        }

        // Get employee
        let employee = null;

        if (formData.employee) {
            let url = '/api/employees/' + GeneralAction.iriToId(formData.employee);
            employee = await APIAction.request({ method: 'GET', url: url });
        }

        // Check client settings: override minimum duration of shift?
        let overrideMinimumDuration = await ClientAction.getClientSetting('override_shift_duration');
        if (overrideMinimumDuration === 'true') {
            minimumDuration = await ClientAction.getClientSetting('minimum_shift_duration');
        }

        // Update minimum duration for Flex-job (to prevent violation of terms FLX)
        /*if (employee) {
            if (employee.contractType && employee.contractType.abbreviation === 'FLX') {
                minimumDuration = 5;
            }
        }*/

        if (
            (!formData.position)
        ) {
            await GeneralAction.toast('error', <Trans>Fill in required fields</Trans>);
        } else if (startOn.getDate() !== currentDate.getDate() || startOn.getMonth() !== currentDate.getMonth() || startOn.getFullYear() !== currentDate.getFullYear()) {
            await GeneralAction.toast('error', <Trans>Must start in current day</Trans>)
        } else if (startOn > endOn) {
            await GeneralAction.toast('error', <Trans>Dates in wrong order</Trans>);
        } else if ((new Date(endOn).getTime() - new Date(startOn).getTime()) < (minimumDuration * 60 * 60 * 1000)) {
            let errorMessage = translations.Minimum + ' ' + translations.length + ': ' + minimumDuration + ' ' + translations.hours;
            await GeneralAction.toast('error', errorMessage);
        } else if ((new Date(startOn)).getTime() <= (new Date(new Date().setMinutes(new Date().getMinutes() + 30)))) {
            await GeneralAction.toast('error', <Trans>Start has to be at least 30 min from current time</Trans>);
        } else {

            //check if is new or old
            let result = false;
            if (!formData.shiftId) {
                result = await APIAction.request({
                    method: 'POST', url: '/api/shifts', body: {
                        startOn: startOn,
                        endOn: endOn,
                        position: formData.position,
                        department: formData.department
                    }, params: {
                        employeeId: formData.employee ? GeneralAction.iriToId(formData.employee) : null
                    }, catchFail: false
                });
            } else {
                result = await APIAction.request({
                    method: 'PATCH', url: `/api/shifts/${formData.shiftId}`, body: {
                        startOn: startOn,
                        endOn: endOn,
                        position: formData.position,
                        department: formData.department
                    }, params: {
                        employeeId: formData.employee ? GeneralAction.iriToId(formData.employee) : 'null',
                        employeeShiftId: props.shift.id
                    }, catchFail: false
                });
            }

            if (result === false) {
                await GeneralAction.toast('error', <Trans>Cannot make shift</Trans>);
            } else {
                if ('status' in result && result.status === 'NOK') {
                    await GeneralAction.toast('error', result.message);
                } else {
                    await GeneralAction.toast('success', <Trans>Shift created</Trans>);
                    props.onClose();
                    //clear cache
                    await RequestCacheAction.clear({ url: 'shift' });
                    //send reload signal
                    if (typeof props.reload === 'function') {
                        props.reload();
                    }
                }
            }
        }

        setLoading(false);
    };

    return (
        <Drawer open={props.open} onClose={() => props.onClose()}>
            {null === formData ?
                <VStack space={3} p={3}>
                    <Skeleton.Text />
                    <Skeleton.Text />
                    <Skeleton />
                </VStack>
                :
                <Box p={3}>
                    <Box>
                        <Text color={'secondary.600'} fontSize={14} fontWeight={'bold'} textTransform={'uppercase'}><Trans>Detail shift</Trans> - {fullDate}</Text>
                    </Box>
                    <Stack
                        my={2}
                        direction={'row'}
                        space={2}
                    >
                        <Box flex={1}>
                            <FormControl>
                                <FormControl.Label><Text><Trans>Start hour</Trans></Text></FormControl.Label>
                                <DateTimePicker
                                    minimumDate={(new Date(props.date)).setHours(0, 0, 0, 0)}
                                    maximumDate={(new Date(props.date)).setHours(23, 59, 59, 999)}
                                    mode={'time'}
                                    display={'clock'}
                                    value={new Date(formData.startOn)}
                                    onChange={(value) => {
                                        setFormData({ ...formData, ...{ startOn: value } });
                                    }}
                                />
                            </FormControl>
                        </Box>
                        <Box flex={1}>
                            <FormControl>
                                <FormControl.Label><Text><Trans>End hour</Trans></Text></FormControl.Label>
                                <DateTimePicker
                                    mode={'time'}
                                    display={'spinner'}
                                    /*minimumDate={(new Date(new Date(formData.startOn).setHours(new Date(formData.startOn).getHours() + 3)))}*/
                                    value={new Date(formData.endOn)}
                                    onChange={(value) => {
                                        setFormData({ ...formData, ...{ endOn: value } });
                                    }}
                                />
                            </FormControl>
                        </Box>
                    </Stack>
                    <Stack
                        my={2}
                        direction={{
                            md: 'row',
                            base: 'column'
                        }}
                        space={{
                            md: 2,
                            base: 4
                        }}
                    >
                        <Box flexGrow={1} flexShrink={1} flexBasis={{ md: 1 }}>
                            <Box>
                                <FormControl.Label><Text><Trans>Position</Trans></Text></FormControl.Label>
                                <Select
                                    placeholder={translations['Select']}
                                    selectedValue={formData.position}
                                    onValueChange={(val) => {
                                        setFormData({ ...formData, ...{ position: val } });
                                    }}
                                >
                                    {positions}
                                </Select>
                            </Box>
                        </Box>
                        {(departments.length > 0) &&
                            <Box flexGrow={1} flexShrink={1} flexBasis={{ md: 1 }}>
                                <FormControl>
                                    <FormControl.Label><Text><Trans>Department</Trans></Text></FormControl.Label>
                                    <Select
                                        placeholder={translations['Select']}
                                        selectedValue={formData.department}
                                        onValueChange={(val) => {
                                            setFormData({ ...formData, ...{ department: val } });
                                        }}
                                    >
                                        {departments}
                                    </Select>
                                </FormControl>
                            </Box>
                        }
                    </Stack>
                    <Stack
                        my={2}
                        direction={{
                            md: 'row',
                            base: 'column'
                        }}
                        space={{
                            md: 2,
                            base: 4
                        }}
                    >
                        <Box flexGrow={1} flexShrink={1} flexBasis={{ md: 1 }}>
                            <Box>
                                <FormControl.Label><Text><Trans>Employee</Trans></Text></FormControl.Label>
                                <Select
                                    key={employeeKey}
                                    placeholder={translations['Select']}
                                    selectedValue={formData.employee}
                                    onValueChange={(val) => {
                                        if (val != 'noEmployee') {
                                            setFormData({ ...formData, ...{ employee: val } });
                                        } else {
                                            setFormData({ ...formData, ...{ employee: undefined } })
                                        }
                                    }}
                                >
                                    {employees}
                                </Select>
                            </Box>
                        </Box>
                    </Stack>
                    <Stack
                        my={2}
                        direction={{ base: 'row' }}
                        space={2}
                    >
                        <Button
                            flex={1}
                            variant={'subtle'}
                            colorScheme={'secondary'}
                            onPress={() => props.onClose()}
                        >
                            <Text color={'secondary.600'}><Trans>Close</Trans></Text>
                        </Button>
                        <Button
                            isDisabled={loading}
                            isLoading={loading}
                            flex={1}
                            variant={'subtle'}
                            onPress={() => submit()}
                        >
                            <Text color={'primary.600'}><Trans>Save</Trans></Text>
                        </Button>
                    </Stack>
                </Box>
            }
        </Drawer>
    );
}

export default CalendarEditPane;