import React from 'react';
import { Platform } from 'react-native';
import axios from 'axios';
import Config from '../Libs/Config';
import { initPerformance, setCrashlyticsId } from '../Libs/Firebase';
import Restart from '../Libs/Restart';
import SecureStorage from '../Libs/SecureStorage';
import SettingAction from './SettingAction';
import UserAction from './UserAction';
import RequestCacheAction from './RequestCacheAction';
import FingerPrinter from '../Libs/FingerPrinter';
import AsyncStorage from '@react-native-async-storage/async-storage';
import MimeDb from 'mime-db';

const APIAction = {

    request: async ({ method, url, body = null, params = null, authenticate = true, catchFail = true, type = 'application/json', returnErrors = false, fileResponse = false, cache = false, abortController = null }) => {

        //performance monitoring
        initPerformance(axios);

        //lowercase
        method = method.toLowerCase();

        //get url
        let requestUrl = Config.API_URL + url;

        //axios config
        let axiosConfig = {
            method: method,
            url: requestUrl,
            headers: {}
        };

        //check if cancel signal
        if (abortController) {
            axiosConfig['signal'] = abortController.signal;
        }

        //if impersonate is set
        let impersonate = await AsyncStorage.getItem('Impersonate');
        if (null !== impersonate) {
            axiosConfig['headers']['X-Switch-User'] = impersonate;
        }

        //if file response
        if (fileResponse) {
            axiosConfig['responseType'] = 'blob';
        } else {
            axiosConfig['headers']['Accept'] = 'application/ld+json'
        }

        //check if params
        if (null !== params) {
            axiosConfig['params'] = params;
        }

        //authorisation
        if (authenticate) {
            let token = await SettingAction.get('Token');
            axiosConfig['headers']['Authorization'] = 'Bearer ' + token;
        }

        //check if body
        if (body !== null) {

            //set content type
            axiosConfig['headers']['Content-Type'] = type + '; charset=utf-8';

            //clone body
            let reqBody = JSON.parse(JSON.stringify(body));

            //if multipart/form-data
            if (type === 'multipart/form-data') {
                reqBody = await APIAction.objToFormData(reqBody);
            }

            //set body
            axiosConfig['data'] = reqBody;
        }

        try {
            //check if should use cache
            if (cache) {
                let cacheResponse = await RequestCacheAction.read({ method: method, url: url, params: params, body: body });
                if (cacheResponse && cacheResponse.response) {
                    return cacheResponse.response;
                } else {
                    //if no record available, initialize
                    await RequestCacheAction.write({ method: method, url: url, params: params, body: body, response: null, finished: false });
                }
            }

            //make request
            let response = await axios(axiosConfig);

            //if file return
            if (fileResponse) {
                let fileName = 'unknown.blob';
                let mimeType = 'application/octet-stream';

                //get file name from header
                if ('content-disposition' in response.headers) {
                    let disposition = response.headers['content-disposition'];
                    disposition = disposition.split(';');
                    for (let element of disposition) {
                        element = element.trim();
                        element = element.replaceAll('"', '');
                        element = element.replaceAll("'", '');
                        if (element.includes('filename=')) {
                            fileName = element.replace('filename=', '');
                        }
                    }
                }

                //get mimeType from header
                if ('content-type' in response.headers) {
                    mimeType = response.headers['content-type'];
                }

                return new File([response.data], fileName, {
                    type: mimeType
                });

            }

            //write to cache
            if (cache) {
                await RequestCacheAction.write({ method: method, url: url, params: params, body: body, response: response.data });
            }

            //return
            return response.data;

        } catch (e) {

            if (e.code === 'ERR_CANCELED') {
                return false;
            }

            console.log(e);

            let error = e.response.data;

            if ('hydra:description' in error) {
                console.log(error['hydra:description']);
            }

            if (!returnErrors) {
                if (!catchFail) {
                    return false;
                } else {
                   Restart.restart();
                }
            } else {
                let error = e.response.data;

                if ("hydra:description" in error) {
                    return {
                        error: error['hydra:description'],
                    }
                }
            }

        }
    },

    objToFormData: async (obj) => {
        let formData = new FormData();

        for (let [index, value] of Object.entries(obj)) {

            //chek if record is array
            if (Array.isArray(value)) {
                await APIAction.flattenArray(formData, index, value);
            } else {
                let parsed = await APIAction.fileHelper(value);
                formData.append(index, parsed);
            }
        }

        return formData;
    },

    flattenArray: async (formData, index, array) => {
        for (let [x, val] of Object.entries(array)) {
            //check if record is array
            if (Array.isArray(val)) {
                await APIAction.flattenArray(formData, index + '[' + x + ']', val);
            } else {
                let parsed = await APIAction.fileHelper(val);
                formData.append(index + '[' + x + ']', parsed);
            }
        }
    },

    fileHelper: async (val) => {
        if (
            (Platform.OS === 'web') &&
            (null !== val) &&
            (typeof val === 'object') &&
            ('uri' in val)
        ) {
            let arr = val.uri.split(',');
            let mime = arr[0].match(/:(.*?);/)[1];
            let bstr = atob(arr[1]);
            let n = bstr.length;
            let u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], val.name, { type: mime });
        } else {
            //add mime type for android

            if (
                (null !== val) &&
                (typeof val === 'object') &&
                ('uri' in val)
            ) {
                let type = 'application/octet-stream'
                let extension = 'bin';
                let lastDotIndex = val.uri.lastIndexOf('.');
                if (lastDotIndex !== -1) {
                    extension = val.uri.slice(lastDotIndex + 1).toLowerCase();
                }

                for (let [key, value] of Object.entries(MimeDb)) {
                    if (value.extensions && value.extensions.includes(extension)) {
                        type = key;
                        break;
                    }
                }

                return { ...val, type: type };
            }
            return val;
        }
    },

    login: async (username, password) => {

        //remove previous token
        await SettingAction.rem('Token');

        //make request
        let response = await APIAction.request({
            method: 'POST', url: '/api/login_check', body: {
                email: username,
                password: password,
                appId: Config.APP_ID
            }, authenticate: false, catchFail: false
        });

        if (response === false) return false;

        //set info
        await SettingAction.set('Token', response.token);
        await SecureStorage.setCredentials(username, password);

        //set user
        await APIAction.authenticationCheck();

        //set device info
        await APIAction.sendDeviceInfo();

        //return
        return true;
    },

    authenticationCheck: async () => {
        let result = await APIAction.request({ method: 'GET', url: '/api/current/user', catchFail: false });
        if (!result) return false;
        await UserAction.setUser(result);
        setCrashlyticsId(result.id);
        return true;
    },

    sendDeviceInfo: async () => {
        let firebaseToken = await SettingAction.get('FirebaseToken');
        if (firebaseToken) {
            let os = Platform.OS;
            let reference = await FingerPrinter.get();
            await APIAction.request({
                method: 'POST', url: '/api/user_devices/add', body: {
                    os: os,
                    firebaseToken: firebaseToken,
                    reference: reference
                }
            });
        }
    },

    impersonate: async (user) => {
        await AsyncStorage.setItem('Impersonate', user);
        await RequestCacheAction.empty();
        await APIAction.authenticationCheck();
        Restart.restart();
    },

    unimpersonate: async () => {
        await AsyncStorage.removeItem('Impersonate');
        await RequestCacheAction.empty();
        await APIAction.authenticationCheck();
        Restart.restart();
    }
};

export default APIAction;