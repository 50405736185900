import React, { useCallback, useEffect, useRef, useState } from 'react';
import AppContainer from '../Components/AppContainer';
import { StyleSheet, Linking, Platform, TouchableOpacity } from 'react-native';
import {
    Box,
    Text,
    Pressable,
    VStack,
    Stack,
    HStack,
    Heading,
    Button
} from 'native-base';
import main from '../../Assets/Styles/main.json';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../Assets/Fontello/line-awesome-config.json';
import { Calendar } from 'react-native-calendars/src/index';
import APIAction from '../../Actions/APIAction';
import Trans from '../Components/Trans';
import Phone from './Components/Phone';
import Email from './Components/Email';
import Address from './Components/Address';
import Name from './Components/Name';
import Status from './Components/Status';
import Collapsible from '../Components/Collapsible';
import ReasonEmployment from './Components/ReasonEmployment';
import TranslationAction from '../../Actions/TranslationAction';
import GeneralAction from '../../Actions/GeneralAction';
import Upcomingjobs from '../Components/UpcomingJobs';


const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

const PersonScreen = ({ navigation, route }) => {

    const
        [user, setUser] = useState(null),
        [fullAddress, setFullAddress] = useState(null),
        [language, setLanguage] = useState(null)
        ;

    const firstRender = useRef(true);

    const loadData = useCallback(() => {
        const init = async () => {

            //get api user
            let apiUser = await APIAction.request({ method: 'GET', url: `/api/users/${route.params.id}`, cache: false });
            setUser(apiUser);
            //get language
            setLanguage(await TranslationAction.getSelectedLanguage());

            //get country
            let country = await GeneralAction.getCountry(apiUser.employee.address.country);

            //format addess
            setFullAddress(`${apiUser.employee.address.street} ${apiUser.employee.address.streetNumber}, ${apiUser.employee.address.zip} ${apiUser.employee.address.place}, ${country}`);

        };
        init();
    }, [route.params.id]);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            loadData();
        }
    }, [firstRender, loadData]);

    return (
        <AppContainer>
            <VStack space={2}>
                <Name
                    user={user}
                    locale={language ? language.code : 'en'}
                />
                <Stack
                    direction={{
                        base: 'column',
                        sm: 'row'
                    }} 
                    space={2} 
                    style={[main.boxItemNoFlex]}
                    flexWrap={'wrap'}>
                    <Phone
                    style={{ flexGrow: 1, flexShrink: 1 }}
                    user={user}
                    />
                    <Email
                        style={{ flexGrow: 1, flexShrink: 1 }}
                        user={user}
                    />
                    <Address
                        user={user}
                    />
                </Stack>
                <Heading style={mainStyle.pageTitle}><Trans>Upcoming jobs</Trans></Heading>
                <Upcomingjobs user={user} />
                {/*<Status
                    user={user}
                />

                <Collapsible title={<Trans>Last shifts</Trans>}>
                    <VStack space={2}>
                        <Pressable flexGrow={1} flexShrink={1} style={[mainStyle.boxItem, { padding: 10, margin: 0 }]} _pressed={{
                            style: [mainStyle.boxItem, { padding: 10, margin: 0, backgroundColor: '#ccc' }]
                        }}>
                            <HStack space={1} justifyContent={'space-between'} alignItems={'center'} flexGrow={1} flexShrink={1}>
                                <Box>
                                    <HStack space={1} alignItems={'center'}>
                                        <Text style={[mainStyle.shiftItemTitle]}>Kassa</Text>
                                        <Text style={mainStyle.smallText}>(23/11/2022)</Text>
                                    </HStack>
                                </Box>
                                <Box
                                    backgroundColor={'primary.100'}
                                    py={1}
                                    px={2}
                                    borderRadius={6}
                                >
                                    <Text style={{ color: '#00aaff' }}>07:00 - 15:00</Text>
                                </Box>
                            </HStack>
                        </Pressable>
                        <Pressable flexGrow={1} flexShrink={1} style={[mainStyle.boxItem, { padding: 10, margin: 0 }]} _pressed={{
                            style: [mainStyle.boxItem, { padding: 10, margin: 0, backgroundColor: '#ccc' }]
                        }}>
                            <HStack space={1} justifyContent={'space-between'} alignItems={'center'} flexGrow={1} flexShrink={1}>
                                <Box>
                                    <HStack space={1} alignItems={'center'}>
                                        <Text style={mainStyle.shiftItemTitle}>Zaal</Text>
                                        <Text style={mainStyle.smallText}>(23/11/2022)</Text>
                                    </HStack>
                                </Box>
                                <Box
                                    backgroundColor={'primary.100'}
                                    py={1}
                                    px={2}
                                    borderRadius={6}
                                >
                                    <Text style={{ color: '#2ebfa5' }}>11:00 - 18:00</Text>
                                </Box>
                            </HStack>
                        </Pressable>
                    </VStack>
                </Collapsible>

                <Collapsible title={<Trans>Availabilities & Holidays</Trans>}>
                    <Calendar
                        renderArrow={direction => <Icon size={20} name={'angle-' + direction} />}
                        enableSwipeMonths={true}
                    />
                </Collapsible>

                <Collapsible title={<Trans>Hours worked</Trans>}>
                    <VStack space={2}>
                        <Pressable flexGrow={1} flexShrink={1} style={[mainStyle.boxItem, { padding: 10, margin: 0, backgroundColor: '#16A34A' }]} _pressed={{
                            style: [mainStyle.boxItem, { padding: 10, margin: 0, backgroundColor: '#ccc' }]
                        }}>
                            <HStack space={1} justifyContent={'space-between'} alignItems={'center'} flexGrow={1} flexShrink={1}>
                                <Box>
                                    <HStack space={1} alignItems={'center'}>
                                        <Icon name={'check-circle'} size={24} style={{ color: '#ffffff' }} />
                                        <Text style={[mainStyle.shiftItemTitle, { color: '#fff' }]}>Sunday</Text>
                                        <Text style={[mainStyle.smallText, { color: '#fff' }]}>(23/11/2022)</Text>
                                    </HStack>
                                </Box>
                                <Box
                                    backgroundColor={'gray.100'}
                                    py={1}
                                    px={2}
                                    borderRadius={6}
                                >
                                    <Text color={'success.600'}>07:00 - 15:00</Text>
                                </Box>
                            </HStack>
                        </Pressable>
                        <Pressable flexGrow={1} flexShrink={1} style={[mainStyle.boxItem, { padding: 10, margin: 0 }]} _pressed={{
                            style: [mainStyle.boxItem, { padding: 10, margin: 0, backgroundColor: '#ccc' }]
                        }}>
                            <HStack space={1} justifyContent={'space-between'} alignItems={'center'} flexGrow={1} flexShrink={1}>
                                <Box>
                                    <HStack space={1} alignItems={'center'}>
                                        <Icon name={'alternate-redo'} size={24} style={{ color: 'rgba(0,0,0,1)' }} />
                                        <Text style={mainStyle.shiftItemTitle}>Monday</Text>
                                        <Text style={mainStyle.smallText}>(23/11/2022)</Text>
                                    </HStack>
                                </Box>
                                <Box
                                    backgroundColor={'gray.100'}
                                    py={1}
                                    px={2}
                                    borderRadius={6}
                                >
                                    <Text>07:00 - 19:00</Text>
                                </Box>
                            </HStack>
                        </Pressable>
                        <Pressable flexGrow={1} flexShrink={1} style={[mainStyle.boxItem, { padding: 10, margin: 0 }]} _pressed={{
                            style: [mainStyle.boxItem, { padding: 10, margin: 0, backgroundColor: '#ccc' }]
                        }}>
                            <HStack space={1} justifyContent={'space-between'} alignItems={'center'} flexGrow={1} flexShrink={1}>
                                <Box>
                                    <HStack space={1} alignItems={'center'}>
                                        <Icon name={'alternate-redo'} size={24} style={{ color: 'rgba(0,0,0,1)' }} />
                                        <Text style={mainStyle.shiftItemTitle}>Tuesday</Text>
                                        <Text style={mainStyle.smallText}>(24/11/2022)</Text>
                                    </HStack>
                                </Box>
                                <Box
                                    backgroundColor={'gray.100'}
                                    py={1}
                                    px={2}
                                    borderRadius={6}
                                >
                                    <Text>11:00 - 18:00</Text>
                                </Box>
                            </HStack>
                        </Pressable>
                    </VStack>
                </Collapsible>

                <Collapsible title={<Trans>Sick days</Trans>}>
                    <VStack space={2}>
                        <Pressable flexGrow={1} flexShrink={1} style={[mainStyle.boxItem, { padding: 10, margin: 0 }]} _pressed={{
                            style: [mainStyle.boxItem, { padding: 10, margin: 0, backgroundColor: '#ccc' }]
                        }}>
                            <HStack space={1} justifyContent={'space-between'} alignItems={'center'} flexGrow={1} flexShrink={1}>
                                <Box>
                                    <HStack space={1} alignItems={'center'}>
                                        <Icon name={'alternate-file'} size={24} style={{ color: 'rgba(0,0,0,1)' }} />
                                        <Text style={mainStyle.shiftItemTitle}>23 March 2022</Text>
                                        <Text style={mainStyle.smallText}>(Document name here)</Text>
                                    </HStack>
                                </Box>
                                <Icon name={'angle-right'} size={16} style={{ opacity: 0.5 }} />
                            </HStack>
                        </Pressable>
                        <Pressable flexGrow={1} flexShrink={1} style={[mainStyle.boxItem, { padding: 10, margin: 0 }]} _pressed={{
                            style: [mainStyle.boxItem, { padding: 10, margin: 0, backgroundColor: '#ccc' }]
                        }}>
                            <HStack space={1} justifyContent={'space-between'} alignItems={'center'} flexGrow={1} flexShrink={1}>
                                <Box>
                                    <HStack space={1} alignItems={'center'}>
                                        <Icon name={'alternate-file'} size={24} style={{ color: 'rgba(0,0,0,1)' }} />
                                        <Text style={mainStyle.shiftItemTitle}>26 March 2022</Text>
                                        <Text style={mainStyle.smallText}>(Document name here)</Text>
                                    </HStack>
                                </Box>
                                <Icon name={'angle-right'} size={16} style={{ opacity: 0.5 }} />
                            </HStack>
                        </Pressable>
                    </VStack>
                </Collapsible>

                <Collapsible title={<Trans>Documents</Trans>}>
                    <VStack space={2}>
                        <Pressable flexGrow={1} flexShrink={1} style={[mainStyle.boxItem, { padding: 10, margin: 0 }]} _pressed={{
                            style: [mainStyle.boxItem, { padding: 10, margin: 0, backgroundColor: '#ccc' }]
                        }}>
                            <HStack space={1} justifyContent={'space-between'} alignItems={'center'} flexGrow={1} flexShrink={1}>
                                <Box>
                                    <HStack space={1} alignItems={'center'}>
                                        <Icon name={'alternate-file'} size={24} style={{ color: 'rgba(0,0,0,1)' }} />
                                        <VStack>
                                            <Text style={mainStyle.shiftItemTitle}><Trans>Identity documents</Trans></Text>
                                            <Text style={mainStyle.smallText}>28 February 2022 11:08 | .PDF (15mb)</Text>
                                        </VStack>
                                    </HStack>
                                </Box>
                                <Icon name={'angle-right'} size={16} style={{ opacity: 0.5 }} />
                            </HStack>
                        </Pressable>
                        <Pressable flexGrow={1} flexShrink={1} style={[mainStyle.boxItem, { padding: 10, margin: 0 }]} _pressed={{
                            style: [mainStyle.boxItem, { padding: 10, margin: 0, backgroundColor: '#ccc' }]
                        }}>
                            <HStack space={1} justifyContent={'space-between'} alignItems={'center'} flexGrow={1} flexShrink={1}>
                                <Box>
                                    <HStack space={1} alignItems={'center'}>
                                        <Icon name={'alternate-file'} size={24} style={{ color: 'rgba(0,0,0,1)' }} />
                                        <VStack>
                                            <Text style={mainStyle.shiftItemTitle}><Trans>Legal</Trans></Text>
                                            <Text style={mainStyle.smallText}>28 February 2022 11:08 | .PDF (15mb)</Text>
                                        </VStack>
                                    </HStack>
                                </Box>
                                <Icon name={'angle-right'} size={16} style={{ opacity: 0.5 }} />
                            </HStack>
                        </Pressable>
                        <Pressable flexGrow={1} flexShrink={1} style={[mainStyle.boxItem, { padding: 10, margin: 0 }]} _pressed={{
                            style: [mainStyle.boxItem, { padding: 10, margin: 0, backgroundColor: '#ccc' }]
                        }}>
                            <HStack space={1} justifyContent={'space-between'} alignItems={'center'} flexGrow={1} flexShrink={1}>
                                <Box>
                                    <HStack space={1} alignItems={'center'}>
                                        <Icon name={'alternate-file'} size={24} style={{ color: 'rgba(0,0,0,1)' }} />
                                        <VStack>
                                            <Text style={mainStyle.shiftItemTitle}><Trans>Contract</Trans></Text>
                                            <Text style={mainStyle.smallText}>28 February 2022 11:08 | .PDF (15mb)</Text>
                                        </VStack>
                                    </HStack>
                                </Box>
                                <Icon name={'angle-right'} size={16} style={{ opacity: 0.5 }} />
                            </HStack>
                        </Pressable>
                    </VStack>
                    </Collapsible>*/}

                {/* <ReasonEmployment
                    user={user}
                /> */}
            </VStack>
        </AppContainer>
    );
};

export default PersonScreen;
