import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Box, Heading, HStack, Text, Pressable, Button, Stack, useTheme, Skeleton, Spinner, VStack } from 'native-base';
import lineAwesomeConfig from '../../Assets/Fontello/line-awesome-config.json';
import main from '../../Assets/Styles/main.json';
import APIAction from '../../Actions/APIAction';
import Trans from '../Components/Trans';
import GeneralAction from '../../Actions/GeneralAction';
import Request from '../Job/Request';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import UserAction from "../../Actions/UserAction";

const Icon = createIconSetFromFontello(lineAwesomeConfig);

const mainStyle = StyleSheet.create(main);

const Jobrequests = (props) => {

    const [requests, setRequests] = useState([]);
    const [showJobRequests, setShowJobRequests] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [client, setClient] = useState('');
    const cancelJobPromos = useRef(new AbortController());

    const themeColors = useTheme()['colors'];

    useEffect(() => {
        let init = async () => {
            let user = await UserAction.getUser();
            let clientId = GeneralAction.iriToId(user.client);
            setClient(clientId);
        }
        init();
    }, [setClient]);

    useEffect(() => {
        const fetchData = async () => {
            //set abort trigger
            cancelJobPromos.current.abort();
            cancelJobPromos.current = new AbortController();
            let requests = await APIAction.request({
                method: 'get', url: '/api/get/accepted/job_promos', params: {
                    startDate: props.startDate,
                    endDate: props.endDate
                },
                abortController: cancelJobPromos.current
            });
            if ( false === requests) {
                return;
            }
            if ('hydra:member' in requests) {
                requests = requests['hydra:member'];
            }

            // Create an object to store requests grouped by shift
            const requestGroups = {};

            for (const key in requests) {
                const request = requests[key];

                //Check if user is already assigned to shift
                let userIsAssignedToShift = false;
                for (const key2 in request.jobPromo.shift.employeeShifts) {
                    let employeeShift = request.jobPromo.shift.employeeShifts[key2];
                    if (employeeShift.employee?.id == request.employee.id) {
                        userIsAssignedToShift = true;
                    }
                }

                // Add only unassigned and undenied jobRequests
                if (!request.denied && !userIsAssignedToShift) {
                    const shiftId = request.jobPromo.id;
                    // If the shift group doesn't exist, create it
                    if (!requestGroups[shiftId]) {
                        requestGroups[shiftId] = [];
                    }

                    // Push the request to the appropriate shift group
                    requestGroups[shiftId].push(request);
                }
            }

            // Convert the object of grouped requests to an array of arrays
            // const requestArray = Object.values(requestGroups);

            let shifts = [];
            for (const key in requestGroups) {
                const shift = requestGroups[key];

                let aliasOrName = (client ? await GeneralAction.getAlias(shift[0].jobPromo.shift.position, client) : shift[0].jobPromo.shift.position.name);

                //If shift is fully booked, dont show it anymore
                if (shift[0].jobPromo.shift.amountFilledIn < shift[0].jobPromo.shift.quantity) {
                    shifts.push(
                        <Box style={[mainStyle.boxItem, { paddingVertical: 10 }]}>
                            <Box style={{ flex: 1 }}>
                                <Stack
                                    direction={props.small ? 'column' : {
                                        md: 'row',
                                        base: 'column'
                                    }}
                                    alignItems={props.small ? 'flex-start' : {
                                        md: 'center',
                                        base: 'flex-start'
                                    }}
                                    justifyContent={'space-between'}
                                    space={props.small ? 2 : {
                                        md: 0,
                                        base: 2
                                    }}
                                    style={{ marginVertical: 10 }}
                                >
                                    <HStack alignItems={'center'} justifyContent={props.small ? 'space-between' : 'auto'} width={props.small ? '100%' : 'auto'}>
                                        <VStack>
                                            {props.small &&
                                                <Text style={[mainStyle.mediumTitle]}>{shift[0].jobPromo?.shift?.project?.name}</Text>}
                                            <Text>{aliasOrName} </Text>
                                        </VStack>
                                        <HStack alignItems={'center'} style={{ backgroundColor: themeColors['primary']['100'], paddingHorizontal: 7, paddingVertical: 7, borderRadius: 8, marginRight: 10 }}>
                                            <Text color={themeColors['primary']['600']} style={{ fontSize: 12 }}>{shift[0].jobPromo.shift.amountFilledIn}/{shift[0].jobPromo.shift.quantity}</Text>
                                            <Icon color={themeColors['primary']['600']} name={'user'} size={17} />
                                        </HStack>
                                    </HStack>
                                    <HStack alignItems={'center'} justifyContent={props.small ? 'space-between' : 'auto'} width={props.small ? '100%' : 'auto'}>
                                        <Text style={[mainStyle.dateDayLabel, { marginRight: 10 }]}>{GeneralAction.formatDateFull(shift[0].jobPromo.shift.startOn, 'nl')}</Text>
                                        <Text style={mainStyle.timeLabel}>{GeneralAction.formatTime(shift[0].jobPromo.shift.startOn)} - {GeneralAction.formatTime(shift[0].jobPromo.shift.endOn)}</Text>
                                    </HStack>
                                </Stack>
                                {shift.map(function (request) {
                                    return <Request
                                            promo={request}
                                            reload={() => {
                                                fetchData();
                                                if (typeof props.onReload === 'function') {
                                                    props.onReload();
                                                }
                                            }}
                                            small={props.small}
                                        />
                                })}
                            </Box>
                        </Box>
                    );
                }
            }

            setRequests(shifts);
            setIsLoading(false);
        };
        if (client) {
            fetchData();
        }
    }, [themeColors, props, client]);

    return (
        <>
            {isLoading ?
                <>
                    {props.small ?
                        <Skeleton style={{ marginTop: 20 }} />
                        :
                        <Box p={3}>
                            <Spinner size={'lg'} />
                        </Box>
                    }
                </>
                :
                <>
                    {requests.length > 0 &&
                        <Pressable
                            onPress={() => { setShowJobRequests(!showJobRequests) }}
                            style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 10 }}
                        >
                            <Heading style={mainStyle.pageTitle}>
                                <Icon name={showJobRequests ? 'angle-down' : 'angle-right'} style={{ marginRight: 5 }} />
                                <Trans>Job requests</Trans>
                            </Heading>
                            <Button variant={'subtle'} onPress={() => { setShowJobRequests(!showJobRequests) }}>
                                <Text color={themeColors['primary']['600']} style={{ display: showJobRequests ? 'flex' : 'none' }}>
                                    <Trans>Collapse</Trans>
                                </Text>
                                <Text color={themeColors['primary']['600']} style={{ display: showJobRequests ? 'none' : 'flex' }}>
                                    <Trans>Show</Trans>
                                </Text>
                            </Button>
                        </Pressable>}
                    <Box
                        style={{ display: showJobRequests ? 'flex' : 'none' }}
                        mt={3}
                    >
                        {requests}
                    </Box>
                </>
            }
        </>
    );
};

export default Jobrequests;
