import React, { useState, useEffect } from 'react';
import { StyleSheet, Platform } from 'react-native';
import { Box, Divider, Text, Heading, View, Stack, HStack, VStack, Button, AlertDialog, FormControl, TextArea } from 'native-base';
import GeneralAction from '../../Actions/GeneralAction';
import APIAction from '../../Actions/APIAction';
import main from '../../Assets/Styles/main.json';
import TranslationAction from '../../Actions/TranslationAction';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../Assets/Fontello/line-awesome-config.json';
import EmployeeItem from "../Organization/Components/EmployeeItem";
import { v4 as uuidv4 } from "uuid";
import Trans from '../Components/Trans';

const mainStyle = StyleSheet.create(main);
const Icon = createIconSetFromFontello(lineAwesomeConfig);

const Request = (props) => {
    const promo = props.promo;

    let [denyModal, setDenyModal] = useState(false);
    let [reason, setReason] = useState(null);
    let [isAssigning, setIsAssigning] = useState(false);
    let [isDenying, setIsDenying] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
        };
        fetchData();
    }, []);

    const assignJob = async (jobPromoId, employee, shift) => {
        setIsAssigning(true);
        let url = `/api/job_promos/${jobPromoId}/assign`;
        let result = await APIAction.request({
            method: 'PATCH', url: url, body: {
                employeeId: employee.id,
                shiftId: shift.id
            }
        });

        if (result === false) {
            await GeneralAction.toast('error', <Trans>Cannot assign employee</Trans>);
        } else {
            if ('status' in result && result.status === 'NOK') {
                await GeneralAction.toast('error', result.message);
            } else {
                await GeneralAction.toast('success', <Trans>Employee has been assigned to this job</Trans>);
                //send reload signal
                if (typeof props.reload === 'function') {
                    props.reload();
                }
            }
        }
        setIsAssigning(false);
    }

    const confirmDeny = () => {
        setDenyModal(true);
    }

    const denyJob = async (jobPromoId, employee, shift) => {
        if (reason == null || reason == "") {
            await GeneralAction.toast('error', <Trans>Please provide a reason</Trans>)
        }
        else {
            setIsDenying(true);
            let url = `/api/job_promos/${jobPromoId}/deny`;
            let result = await APIAction.request({
                method: 'POST', url: url, body: {
                    reason: reason,
                    employeeId: employee.id,
                    shiftId: shift.id
                }, params: {}
            });

            if (result === false) {
                await GeneralAction.toast('error', <Trans>Cannot deny job promo</Trans>);
            } else {
                if ('status' in result && result.status === 'NOK') {
                    await GeneralAction.toast('error', result.message);
                } else {
                    setDenyModal(false);
                    await GeneralAction.toast('success', <Trans>Job promo has been denied</Trans>);
                    //send reload signal
                    if (typeof props.reload === 'function') {
                        props.reload();
                    }
                }
            }
            setIsDenying(false);
        }
    }

    return (

        <Box style={[props.headerStyle ? '' : mainStyle.boxItem, { paddingVertical: 0 }]}>
            <Box style={{ flex: 1 }}>
                <Stack
                    direction={props.small ? 'row' : {
                        md: 'row',
                        base: 'column'
                    }}
                    alignItems={props.small ? 'flex-start' : {
                        md: 'center',
                        base: 'flex-start'
                    }}
                    justifyContent={'space-between'}
                    space={2}
                    style={{ marginVertical: 10 }}
                >
                    <Box>
                        <Stack
                            direction={props.small ? 'column' : {
                                md: 'row',
                                base: 'column'
                            }}
                            alignItems={'center'}
                            space={props.small ? 0 : {
                                md: 4,
                                base: 0
                            }}
                        >
                            <EmployeeItem key={uuidv4()} data={promo.employee} viewOnly={true} noPressable={true} small={props.small} />
                        </Stack>
                    </Box>
                    <Box
                        width={props.small ? 'auto' : {
                            base: '100%',
                            md: 'auto'
                        }}
                    >
                        <Stack
                            direction={'row'}
                            alignItems={'center'}
                            space={4}
                        >
                            <Button
                                flex={props.small ? (Platform.OS === 'android' ? 0 : 'inherit') : {
                                    base: 1,
                                    md: Platform.OS === 'android' ? 0 : 'auto'
                                }}
                                isLoading={isAssigning}
                                variant={'subtle'}
                                style={props.small ? {paddingHorizontal: 7, paddingVertical: 3} : {}}
                                onPress={() => {
                                    assignJob(promo.jobPromo.id, promo.employee, promo.jobPromo.shift);
                                }}
                            >
                                <Text color={'primary.600'}><Trans>Assign</Trans></Text>
                            </Button>
                            <Button
                                flex={props.small ? (Platform.OS === 'android' ? 0 : 'inherit') : {
                                    base: 1,
                                    md: Platform.OS === 'android' ? 0 : 'auto'
                                }}
                                isLoading={isDenying}
                                variant={'subtle'}
                                style={props.small ? {paddingHorizontal: 7, paddingVertical: 3} : {}}
                                colorScheme={'danger'}
                                onPress={() => {
                                    confirmDeny(promo.jobPromo.id, promo.employee, promo.jobPromo.shift);
                                }}
                            >
                                <Text color={"#FF6363"}><Trans>Deny</Trans></Text>
                            </Button>
                        </Stack>
                    </Box>
                </Stack>
            </Box>
            {props.headerStyle &&
                <Divider style={{ marginTop: 15 }} />}
            <AlertDialog isOpen={denyModal} onClose={() => setDenyModal(false)}>
                <AlertDialog.Content>
                    <AlertDialog.CloseButton />
                    <AlertDialog.Header><Text><Trans>Deny job promo</Trans></Text></AlertDialog.Header>
                    <AlertDialog.Body>
                        <Text style={{ fontWeight: 'bold' }}><Trans>Are you sure you want to deny the job promo of</Trans> {promo.employee.user.firstName} {promo.employee.user.lastName}?</Text>
                        <FormControl>
                            <FormControl.Label><Text><Trans>Reason</Trans></Text></FormControl.Label>
                            <TextArea
                                value={reason}
                                onChangeText={(value) => {
                                    setReason(value);
                                }}
                            />
                        </FormControl>
                    </AlertDialog.Body>
                    <AlertDialog.Footer>
                        <Button.Group space={2}>
                            <Button variant={'ghost'} onPress={() => setDenyModal(false)}>
                                <Text><Trans>Cancel</Trans></Text>
                            </Button>
                            <Button
                                isLoading={isDenying}
                                colorScheme={'danger'}
                                onPress={() => {
                                    denyJob(promo.jobPromo.id, promo.employee, promo.jobPromo.shift);
                                }}
                            >
                                <Text color={'#fff'}><Trans>Deny</Trans></Text>
                            </Button>
                        </Button.Group>
                    </AlertDialog.Footer>
                </AlertDialog.Content>
            </AlertDialog>
        </Box>
    );
};

export default Request;
