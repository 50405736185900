import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import main from '../../../Assets/Styles/main.json';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import { Box, HStack, Heading, Pressable, Text, IconButton, useTheme, Stack } from 'native-base';
import Trans from '../../Components/Trans';
import { useNavigation } from "@react-navigation/native";
import UserImage from '../../Components/UserImage';
import TranslationAction from '../../../Actions/TranslationAction';
import GeneralAction from '../../../Actions/GeneralAction';

const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

const EmployeeItemBadge = (props) => {
    return (
        <Box
            backgroundColor={`${props.colorScheme}.100`}
            borderRadius={'xl'}
            my={1}
            px={2}
            py={1}
            flexShrink={1}
        >
            <Text color={`${props.colorScheme}.600`} fontSize={10}>{props.text}</Text>
        </Box>
    );
};

const EmployeeItem = (props) => {
    const navigation = useNavigation();

    const { colors } = useTheme();

    const
        [badges, setBadges] = useState([]),
        [editable, setEditable] = useState(false),
        [language, setLanguage] = useState(null),
        [timeWorked, setTimeWorked] = useState(null)
        ;

    let viewOnly = false;
    let showStatus = false;

    if (props.viewOnly) {
        viewOnly = props.viewOnly;
    }

    if (props.showStatus) {
        showStatus = props.showStatus;
    }

    useEffect(() => {


        /**
         * Funciton to check status of employee
         */
        const checkStatus = async () => {
            if (props.data) {
                let employee = props.data;
                let completedSteps = employee.completedSteps;

                // New badges
                let newBadges = [];

                // Check if not approved
                if (!employee.reasonDenied) {
                    if (!employee.identityVerified && !employee.paymentVerified) {
                        // Check which step(s) are completed
                        if (completedSteps && completedSteps.length > 0) {
                            if (completedSteps.includes('approvalRequested')) {
                                // Approval requested
                                newBadges.push(
                                    <EmployeeItemBadge
                                        colorScheme={'success'}
                                        text={<Trans>Approval pending</Trans>}
                                    />
                                );
                            } else if (completedSteps.includes('additionalInfo')) {
                                // Additional info
                                newBadges.push(
                                    <EmployeeItemBadge
                                        colorScheme={'warning'}
                                        text={<Trans>Filled in additional info</Trans>}
                                    />
                                );
                            } else if (completedSteps.includes('paymentVerified')) {
                                // Payment verified
                                newBadges.push(
                                    <EmployeeItemBadge
                                        colorScheme={'warning'}
                                        text={<Trans>Payment verified</Trans>}
                                    />
                                );
                            } else if (completedSteps.includes('identityVerified')) {
                                // Identity verified
                                newBadges.push(
                                    <EmployeeItemBadge
                                        colorScheme={'warning'}
                                        text={<Trans>Identity verified</Trans>}
                                    />
                                );
                            } else if (completedSteps.includes('personalInfo')) {
                                // Personal info
                                newBadges.push(
                                    <EmployeeItemBadge
                                        colorScheme={'warning'}
                                        text={<Trans>Filled in personal info</Trans>}
                                    />
                                );
                            } else if (completedSteps.includes('choosePassword')) {
                                // Status: password chosen
                                newBadges.push(
                                    <EmployeeItemBadge
                                        colorScheme={'warning'}
                                        text={<Trans>Password chosen</Trans>}
                                    />
                                );
                            }
                        } else {
                            // Default status: no steps completed + invitation sent
                            newBadges.push(
                                <EmployeeItemBadge
                                    colorScheme={'danger'}
                                    text={<Trans>Invited</Trans>}
                                />
                            );
                        }
                    }
                } else {
                    // Default status: no steps completed + invitation sent
                    newBadges.push(
                        <EmployeeItemBadge
                            colorScheme={'danger'}
                            text={<Trans>Denied</Trans>}
                        />
                    );
                }

                if (newBadges.length > 0) {
                    setEditable(true);
                }
                setBadges(newBadges);

                // Set time worked
                if (employee.minutesWorked !== null) {
                    let timeWorked = await GeneralAction.formatMinutes(employee.minutesWorked);
                    setTimeWorked(timeWorked);
                }
            }
        };

        checkStatus();
    }, [props, colors]);

    useEffect(() => {
        const init = async () => {
            let selectedLanguage = await TranslationAction.getSelectedLanguage();
            setLanguage(selectedLanguage);
        };
        init();
    }, []);

    return (
        <Box>
            <HStack
                space={2}
                alignItems={'center'}
            >
                <Box
                    flexGrow={1}
                    flexShrink={1}
                >
                    <Pressable 
                        isDisabled={props.noPressable}
                        onPress={() => {
                            navigation.navigate('Organization', {
                                screen: 'Person',
                                params: {
                                    'id': props.data.user.id
                                }
                            });
                        }}
                        
                    >
                        <HStack
                            space={2}
                            alignItems={'center'}
                        >
                            <Box>
                                <UserImage
                                    style={props.small ? {height: 27, width: 27} : { height: 37, width: 37 }}
                                    user={props.data.user}
                                />
                            </Box>
                            <Box
                                flexShrink={1}
                            >

                                <HStack
                                    space={2}
                                    alignItems={'center'}
                                    flexWrap={'wrap'}
                                >
                                    <Heading style={[mainStyle.listDataTitle, { flexGrow: 0 }, props.small && {fontSize: 12, fontWeight: 'normal'} ]}>
                                        {
                                            (props.data.user.firstName || props.data.user.lastName) &&
                                            <>{props.data.user.firstName} {props.data.user.lastName}</>
                                        }
                                        {
                                            !props.data.user.firstName && !props.data.user.lastName &&
                                            <>{props.data.user.email}</>
                                        }

                                    </Heading>

                                    {
                                        props.data.contractType &&
                                        <EmployeeItemBadge
                                            colorScheme={'light'}
                                            text={language ? props.data.contractType.name[language.code] : ''}
                                        />
                                    }

                                    {timeWorked &&
                                        <EmployeeItemBadge
                                            colorScheme={'yellow'}
                                            text={timeWorked}
                                        />
                                    }
                                    {showStatus && badges}
                                </HStack>

                            </Box>
                        </HStack>
                    </Pressable>
                </Box>
                {!viewOnly && editable &&
                    <Box>
                        <IconButton
                            variant={'subtle'}
                            colorScheme={'warning'}
                            borderRadius={'full'}
                            icon={<Icon color={colors['warning']['600']} size={16} name={'alternate-pencil'} />}
                            onPress={() => { navigation.navigate('CompleteProfile', { 'id': props.data.user.id }); }}
                        />
                    </Box>
                }
            </HStack>
        </Box>
    );
};

export default EmployeeItem;
