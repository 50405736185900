import React, { useCallback, useEffect, useState, useRef } from 'react';
import { StyleSheet } from 'react-native';
import { Box, Pressable, Text, HStack, IconButton, useTheme, Skeleton, VStack, AlertDialog, Button } from 'native-base';
import PieChart from '../../Components/PieChart';
import GeneralAction from '../../../Actions/GeneralAction';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import main from '../../../Assets/Styles/main.json';
import lineawesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import ProjectShiftEditPane from './ProjectShiftEditPane';
import Trans from '../../Components/Trans';
import APIAction from '../../../Actions/APIAction';
import TranslationAction from '../../../Actions/TranslationAction';
import RequestCacheAction from '../../../Actions/RequestCacheAction';
import ProjectEmployeeShift from './ProjectEmployeeShift';
import { v4 as uuidv4 } from 'uuid';
import UserAction from "../../../Actions/UserAction";
import InViewPort from '../../Components/InViewPort';
import TriggerAction from '../../../Actions/TriggerAction';

const mainStyle = StyleSheet.create(main);
const Icon = createIconSetFromFontello(lineawesomeConfig);

const ProjectShift = (props) => {

    const
        firstLoad = useRef(true),
        aliasSet = useRef(false),
        [visible, setVisible] = useState(false),
        [dates, setDates] = useState({ start: null, end: null }),
        [openEdit, setOpenEdit] = useState(false),
        openEditFirst = useRef(false),
        editPaneShowEmployeesOnly = useRef(false),
        [openModal, setOpenModal] = useState(false),
        [isLoadingEmployee, setIsLoadingEmployee] = useState(false),
        // [employeeElements, setEmployeeElements] = useState(null),
        [showEmployees, setShowEmployees] = useState(null),
        [assignedEmployeeElements, setAssignedEmployeeElements] = useState(false),
        [translations, setTranslations] = useState({
            'Select': ''
        }),
        [formData, setFormData] = useState({
            shift: props.shift['@id']
        }),
        [alias, setAlias] = useState(''),
        [deleteModal, setDeleteModal] = useState(false)
        ;

    const themeColors = useTheme()['colors'];
    const { colors } = useTheme();

    const onFirstLoad = useCallback(() => {
        const init = async () => {

            /* FORMAT DATES */
            let dateFormat = {
                hour: '2-digit',
                minute: '2-digit'
            };
            let startDate = await GeneralAction.formatLocal(new Date(props.shift.startOn), dateFormat);
            let endDate = await GeneralAction.formatLocal(new Date(props.shift.endOn), dateFormat);
            setDates({ start: startDate, end: endDate });

            let user = await UserAction.getUser();
            let clientId = GeneralAction.iriToId(user.client);

            if (props.shift && props.shift.position && !aliasSet.current) {
                aliasSet.current = true;
                let aliasRequest = await GeneralAction.getAlias(props.shift.position, clientId);
                setAlias(aliasRequest);
            }


            /* EMPLOYEES */
            // let employees = await APIAction.request({
            //     method: 'GET',
            //     url: '/api/employees',
            //     cache: true,
            //     params: {
            //         checkErrors : 1,
            //     },
            // });
            // employees = employees['hydra:member'];

            // let newEmployees = [];
            // for (let employee of employees) {
            //     newEmployees.push(
            //         <Select.Item label={`${employee.user.firstName} ${employee.user.lastName}`} value={employee['@id']} />
            //     );
            // }
            // setEmployeeElements(newEmployees);



        };
        init();
    }, [props]);

    const fetchEmployees = useCallback(() => {
        const init = async () => {
            setIsLoadingEmployee(true);
            /* EMPLOYEE SHIFT */
            let abortController = TriggerAction.getTigger('projectScreenCancel');
            let employeeShifts = await APIAction.request({
                method: 'GET',
                url: '/api/employee_shifts',
                cache: false,
                params: {
                    shiftId: props.shift.id
                },
                abortController: abortController
            });
            if (employeeShifts === false) return;
            employeeShifts = employeeShifts['hydra:member'];
            let newAssigned = [];
            for (let employeeShift of employeeShifts) {
                if (employeeShift.employee) {
                    newAssigned.push(
                        <ProjectEmployeeShift
                            style={{ marginVertical: 4 }}
                            key={uuidv4()}
                            employeeShift={employeeShift}
                            small
                            onUpdate={() => {
                                // init();
                                if (typeof props.onUpdate === 'function') {
                                    props.onUpdate();
                                }
                            }}
                        />
                    );
                }
            }
            setAssignedEmployeeElements(newAssigned);
            setIsLoadingEmployee(false);
            onFirstLoad();
        }
        init();
    }, [onFirstLoad, props]);

    useEffect(() => {
        if (firstLoad.current && visible) {
            firstLoad.current = false;
            onFirstLoad();

            const translate = async () => {
                let newTranslations = await TranslationAction.translateInLine(Object.keys(translations));
                setTranslations(newTranslations);
            };
            translate();

            fetchEmployees();
        }
    }, [onFirstLoad, translations, fetchEmployees, visible]);

    /**
     * Assign employee to shift
     */
    const assignEmployee = async () => {

        //check valididty
        let valid = true;
        if (!formData.shift) {
            valid = false;
            GeneralAction.toast('error', <Trans>No shift</Trans>);
            await GeneralAction.sleep(20);
        }
        if (!formData.employee) {
            valid = false;
            GeneralAction.toast('error', <Trans>No employee</Trans>);
            await GeneralAction.sleep(20);
        }

        if (valid) {
            let result = await APIAction.request({
                method: 'POST',
                url: '/api/employee_shifts',
                body: formData,
                cache: false
            });
            await RequestCacheAction.clear({ url: 'shifts' });

            onFirstLoad();
            if (typeof props.onUpdate === 'function') {
                props.onUpdate();
            }
        }

    };

    const removeShift = async () => {
        let response = await APIAction.request({
            method: 'PATCH',
            url: `/api/shifts/${props.shift.id}`,
            body: {
                deletedAt: new Date()
            }
        });
        await RequestCacheAction.clear({ url: 'shifts' });
        setDeleteModal(false);

        if (typeof props.onUpdate === 'function') {
            props.onUpdate();
        }
    }


    return (
        <>
            <InViewPort onChange={(visible) => {
                if (visible) {
                    setVisible(true);
                }
            }}>
                {visible ?
                    <>
                        <Box style={[props.style, { width: '100%' }]} paddingBottom={props.paddingBottom}>
                            <Box
                                paddingBottom={1}
                                style={{ backgroundColor: 'white', borderRadius: 6, padding: 5 }}
                            >
                                <HStack
                                    space={2}
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                >
                                    <Box flexGrow={1} flexShrink={1}>
                                        <HStack
                                            space={2}

                                        >
                                            <Text opacity={0.6} style={{ fontSize: 11, textAlign: 'center' }} numberOfLines={1}>
                                                {dates.start} - {dates.end}
                                            </Text>
                                            <Text style={{ fontSize: 11, textAlign: 'center' }} numberOfLines={1} flexShrink={1} ellipsizeMode={'tail'}>
                                                {alias ? alias : props.shift.position.name}
                                            </Text>
                                        </HStack>
                                    </Box>
                                    <HStack
                                        space={2}
                                    >
                                        {new Date(props.shift.startOn) > new Date() &&
                                            <IconButton variant={'subtle'} borderRadius={'full'} colorScheme={'warning'} icon={<Icon color={themeColors['warning']['600']} name={'alternate-pencil'} />} onPress={() => {
                                                openEditFirst.current = true;
                                                editPaneShowEmployeesOnly.current = false;
                                                setOpenEdit(true);
                                            }} />}
                                        {new Date(props.shift.startOn) > new Date() && assignedEmployeeElements.length == 0 &&
                                            <IconButton
                                                variant={'subtle'}
                                                colorScheme={'danger'}
                                                borderRadius={'full'}
                                                icon={<Icon color={'#e11d48'} size={16} name={'trash'} />}
                                                onPress={() => {
                                                    setDeleteModal(true);
                                                }}
                                            />}
                                        <Pressable
                                            onPress={() => {
                                                // We fetch the employees at initialisation now
                                                // if (!showEmployees && !assignedEmployeeElements) {
                                                //     fetchEmployees();
                                                // }
                                                // setShowEmployees(!showEmployees);
                                            }}>
                                            <HStack alignItems={'center'} style={{ backgroundColor: themeColors['primary']['100'], paddingHorizontal: 7, paddingVertical: 7, borderRadius: 8 }}>
                                                <Text color={themeColors['primary']['600']} style={{ fontSize: 12 }}>{props.shift.amountFilledIn}/{props.shift.quantity}</Text>
                                                <Icon color={themeColors['primary']['600']} name={'user'} size={17} />
                                            </HStack>
                                        </Pressable>
                                        {/* <PieChart size={32} colorScheme={'primary'} count={props.shift.amountFilledIn} total={props.shift.quantity} /> */}
                                    </HStack>

                                </HStack>
                                {isLoadingEmployee &&
                                    <Skeleton px="4" my="4" rounded="md" />
                                }
                                {/* {assignedEmployeeElements && assignedEmployeeElements.length > 0 && showEmployees && */}
                                <VStack style={{ marginTop: 5 }}>
                                    {assignedEmployeeElements}
                                    {assignedEmployeeElements.length < props.shift.quantity && new Date(props.shift.startOn) > new Date() &&
                                        <Button
                                            w={'100%'}
                                            p={1}
                                            leftIcon={<Icon style={{ color: colors['secondary']['600'] }} size={16} name={'plus'} />}
                                            borderRadius={'md'}
                                            colorScheme={'secondary'}
                                            variant={'outline'}
                                            onPress={() => {
                                                openEditFirst.current = true;
                                                editPaneShowEmployeesOnly.current = true;
                                                setOpenEdit(true);
                                            }}
                                        >
                                            <Text fontSize={'xs'} color={colors['secondary']['600']}><Trans>Add employee</Trans></Text>
                                        </Button>
                                    }
                                </VStack>
                                {/* } */}
                            </Box>
                        </Box>

                        <AlertDialog isOpen={deleteModal} onClose={() => setDeleteModal(false)}>
                            <AlertDialog.Content>
                                <AlertDialog.CloseButton />
                                <AlertDialog.Header><Text><Trans>Delete</Trans></Text></AlertDialog.Header>
                                <AlertDialog.Body>
                                    <Text><Trans>Delete shift:</Trans> <Text bold>{dates.start} - {dates.end} {props.shift.position.alias ? props.shift.position.alias : props.shift.position.name}</Text>?</Text>
                                </AlertDialog.Body>
                                <AlertDialog.Footer>
                                    <Button.Group space={2}>
                                        <Button variant={'ghost'} onPress={() => setDeleteModal(false)}>
                                            <Text><Trans>Cancel</Trans></Text>
                                        </Button>
                                        <Button
                                            colorScheme={'danger'}
                                            onPress={() => {
                                                removeShift();
                                            }}
                                        >
                                            <Text color={'#fff'}><Trans>Delete</Trans></Text>
                                        </Button>
                                    </Button.Group>
                                </AlertDialog.Footer>
                            </AlertDialog.Content>
                        </AlertDialog>
                        {openEditFirst.current &&
                            <ProjectShiftEditPane
                                open={openEdit}
                                project={props.project}
                                shift={props.shift}
                                showEmployeesOnly={editPaneShowEmployeesOnly.current}
                                onClose={() => {
                                    setOpenEdit(!openEdit);
                                }}
                                reload={() => {
                                    onFirstLoad();
                                    if (typeof props.onUpdate === 'function') {
                                        props.onUpdate();
                                    }
                                }}
                            />
                        }
                    </>
                    :
                    <Skeleton h={85} />
                }
            </InViewPort>
        </>
    );
};

export default ProjectShift;
